import React, { useState, useEffect } from 'react'
import InnerHTML from 'dangerously-set-html-content'

const ActiveCampaignForm = ({ formId = '', onComplete = () => {}, resource_name = '' }) => {

  const [form, setForm] = useState(null)

  const ref = React.createRef()

  useEffect(() => {
    fetch(`/api/active-campaign?formId=${formId}`, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        setForm(data.body)
      })
  }, [formId])

  useEffect(() => {
    const $form = ref.current?.querySelector('form')

    $form?.addEventListener('submit', () => {
      if ($form.checkValidity()) {
        onComplete()
      }
    })

    // Find form fields and add data to hidden fields
    const $fields = ref.current?.querySelectorAll('input[type="hidden"]')

    $fields?.forEach($field => {
      // get data-name attribute
      const dataName = $field.getAttribute('data-name')

      switch (dataName) {
        case 'page_url':
          $field.value = window.location.href 
          break
        case 'resource_name':
          $field.value = resource_name
          break
      }

    })

  }, [form, onComplete, ref])

  return (
    <div className='active-campaign-form' ref={ref}>
      <div className='active-campaign-form__frame'>
        { form && <InnerHTML html={form} /> }
      </div>
    </div>
  )
}

export default ActiveCampaignForm