import React from 'react'
import { CaseStudy, Event, Article, Video, Machines, CompanyNews, ProductLaunch } from '../components/icons'

export const colors = {
  'Company News': '#00D991',
  'Case Study': '#F56645',
  'Article': '#00D1D9',
  'Video': '#8F2EDB',
  'Event': '#00D991',
  'Product Launch': '#D1DE00',
}

export const darkColors = {
  '#00D991': '#08141A', // Green
  '#F56645': '#170005', // Orange
  '#00D1D9': '#030A17', // Teal
  '#8F2EDB': '#0F0D14', // Purple 
  '#D1DE00': '#0D0000', // Yellow
  'default': '#080A0D', // Grey
}

export const icons = {
  'Company News': <CompanyNews />,
  'Event': <Event />,
  'Case Study': <CaseStudy />,
  'Article': <Article />,
  'Video': <Video />,
  'Machines': <Machines />,
  'Product Launch': <ProductLaunch />,
}

export const classIcons = {
  'Company News': CompanyNews,
  'Event': Event,
  'Case Study': CaseStudy,
  'Article': Article,
  'Video': Video,
  'Machines': Machines,
  'Product Launch': ProductLaunch,
}
