import React, { Component } from "react"

class Twitter extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.996"
        height="34.996"
        viewBox="0 0 34.996 34.996"
      >
        <path
          id="twitter"
          d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Zm7.839,13.579v.56A11.159,11.159,0,0,1,13.859,25.2,11.63,11.63,0,0,1,7.7,23.518h.98a8.387,8.387,0,0,0,5.039-1.68,3.958,3.958,0,0,1-3.78-2.66,4,4,0,0,0,1.82,0,4.013,4.013,0,0,1-3.22-3.78h0a3.875,3.875,0,0,0,1.82.42,3.7,3.7,0,0,1-1.82-3.22,3.214,3.214,0,0,1,.56-1.96,11.744,11.744,0,0,0,8.259,4.06c0-.28-.14-.56-.14-.84a4.061,4.061,0,0,1,7-2.66,6.778,6.778,0,0,0,2.52-.98h0a3.816,3.816,0,0,1-1.82,2.1,14.645,14.645,0,0,0,2.38-.56A7.988,7.988,0,0,1,25.337,13.579Z"
          fill={color}
        />
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        id="instagram"
        xmlns="http://www.w3.org/2000/svg"
        width="34.996"
        height="34.996"
        viewBox="0 0 34.996 34.996"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="3.08"
          cy="3.08"
          r="3.08"
          transform="translate(14.418 14.418)"
          fill={color}
        />
        <path
          id="Path_606"
          data-name="Path 606"
          d="M22.038,10.84a3.485,3.485,0,0,0-.98-2.66A4.42,4.42,0,0,0,18.4,7.2H10.84A3.382,3.382,0,0,0,7.2,10.84v7.419a3.584,3.584,0,0,0,1.12,2.66,4.42,4.42,0,0,0,2.66.98h7.559a3.485,3.485,0,0,0,2.66-.98,4.109,4.109,0,0,0,.98-2.66h-.14Zm-7.419,8.679a4.9,4.9,0,1,1,4.9-4.9A4.96,4.96,0,0,1,14.619,19.519Zm4.9-8.4a1.323,1.323,0,0,1-1.4-1.4,1.4,1.4,0,1,1,2.8,0A1.323,1.323,0,0,1,19.519,11.12Z"
          transform="translate(2.879 2.879)"
          fill="#00d991"
        />
        <path
          id="Path_607"
          data-name="Path 607"
          d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Zm9.1,21.278a5.268,5.268,0,0,1-1.54,3.92,5.067,5.067,0,0,1-3.78,1.4H13.719a4.863,4.863,0,0,1-3.78-1.4,5.5,5.5,0,0,1-1.54-3.92V13.719A5,5,0,0,1,13.719,8.4h7.559a4.948,4.948,0,0,1,3.78,1.54,5.067,5.067,0,0,1,1.4,3.78v7.559Z"
          fill="#00d991"
        />
      </svg>
    )
  }
}

class LinkedIn extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        id="linkedin"
        xmlns="http://www.w3.org/2000/svg"
        width="34.998"
        height="35"
        viewBox="0 0 34.998 35"
      >
        <path
          id="Path_608"
          data-name="Path 608"
          d="M13.7,10.9Z"
          transform="translate(5.48 4.36)"
          fill={color}
        />
        <path
          id="Path_609"
          data-name="Path 609"
          d="M17.359,0A17.5,17.5,0,1,0,35,17.359,17.5,17.5,0,0,0,17.359,0ZM10.079,24.778h0l-.14-11.059h3.64l.14,10.919Zm1.68-12.6h0a1.843,1.843,0,0,1-2.1-1.82,1.951,1.951,0,0,1,2.1-1.96,2.019,2.019,0,0,1,2.1,1.82A1.951,1.951,0,0,1,11.759,12.179ZM23.1,24.638V18.759c0-1.54-.56-2.52-1.82-2.52a1.972,1.972,0,0,0-1.82,1.4,1.775,1.775,0,0,0-.14.84v6.159h-3.64s0-9.939-.14-10.919h3.64v1.54a3.476,3.476,0,0,1,3.22-1.82c2.38,0,4.2,1.54,4.2,4.9v6.3Z"
          transform="translate(0.001 0.001)"
          fill={color || "#00d991"}
        />
      </svg>
    )
  }
}

class Facebook extends Component {
  render() {
    const { color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.996"
        height="34.996"
        viewBox="0 0 34.996 34.996"
      >
        <path
          id="facebook"
          d="M17.5,0A17.5,17.5,0,1,0,35,17.5,17.5,17.5,0,0,0,17.5,0Zm4.62,17.358h-3.08v9.8h-4.2v-9.8H12.6v-3.22h2.1v-2.1c0-2.8,1.4-4.34,4.9-4.34h2.66v3.36h-1.82c-1.4,0-1.4.56-1.4,1.4V14H22.4Z"
          fill={color}
        />
      </svg>
    )
  }
}

export const Youtube = ({ color = "#00D991" }) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M92.4609 0.429688C50.1563 3.4375 13.75 33.9453 3.28124 75.1562C-7.26563 116.602 9.49218 159.961 45.1172 183.398C57.8906 191.797 71.3672 196.797 87.3047 199.062C93.5156 199.961 106.289 199.961 112.617 199.102C135.586 195.937 156.172 185.586 171.68 169.492C186.641 153.906 195.586 135.664 198.867 113.867C199.961 106.719 200.039 94.2578 199.063 87.3047C195.43 61.7187 183.398 39.6484 164.453 23.75C147.852 9.80469 128.477 1.95312 106.836 0.390625C100.82 -0.0390625 98.7109 -0.0390625 92.4609 0.429688ZM125.977 65.2734C137.266 65.7422 140.039 66.25 142.969 68.2812C148.477 72.0312 150 78.9453 150 100C150 122.852 148.32 129.141 141.172 132.734C138.75 133.945 135.82 134.297 123.477 134.805C110.977 135.312 74.4922 135.039 67.3828 134.375C52.4609 133.008 50 128.086 50 100C50 78.9453 51.5234 72.0312 57.0312 68.2812C59.8828 66.3281 63.0078 65.7031 72.4609 65.2734C83.5156 64.8047 115.273 64.8047 125.977 65.2734Z"
        fill={color}
      />
      <path
        d="M89.8438 98.8281C89.8438 106.328 89.9609 112.5 90.1172 112.5C90.3125 112.5 115.977 99.2969 116.328 99.0234C116.406 98.9453 99.1406 89.8828 90.5469 85.4688C89.8828 85.1563 89.8438 85.8984 89.8438 98.8281Z"
        fill={color}
      />
    </g>
  </svg>
)

class LogoMark extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.611"
        height="45"
        viewBox="0 0 34.611 45"
      >
        <path
          id="Path_577"
          data-name="Path 577"
          d="M0,0V45H7.488V25.953H27.117V45h7.493V19.838H7.488V0ZM27.117,12.168h7.493V0H27.117Z"
          fill={color}
        />
      </svg>
    )
  }
}

class DownArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="23"
        viewBox="0 0 42 23"
      >
        <g
          id="Arrow_Right"
          data-name="Arrow Right"
          transform="translate(42) rotate(90)"
        >
          <path
            id="Arrow_Right_32px"
            data-name="Arrow Right 32px"
            d="M20.223.469.468,19.894a1.543,1.543,0,0,0,0,2.213L20.223,41.531a1.657,1.657,0,0,0,2.295.014,1.544,1.544,0,0,0,.015-2.227L3.9,21,22.533,2.681A1.544,1.544,0,0,0,22.518.454a1.658,1.658,0,0,0-2.295.014Z"
            transform="translate(23 42) rotate(180)"
            fill={color}
          />
        </g>
      </svg>
    )
  }
}

class LeftArrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg
        id="Arrow_Right"
        data-name="Arrow Right"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="30"
        viewBox="0 0 17 30"
      >
        <path
          id="Arrow_Right_32px"
          data-name="Arrow Right 32px"
          d="M14.947.335.346,14.21a1.076,1.076,0,0,0,0,1.581l14.6,13.875a1.256,1.256,0,0,0,1.7.01,1.077,1.077,0,0,0,.011-1.591L2.886,15,16.655,1.915A1.077,1.077,0,0,0,16.644.324a1.256,1.256,0,0,0-1.7.01Z"
          transform="translate(17 30) rotate(180)"
          fill={color}
        />
      </svg>
    )
  }
}

class Mail extends Component {
  render() {
    let { color } = this.props
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.218 13.211">
        <path
          d="M11.242,7.537l3.874,3.874H3.1L6.975,7.537,9.109,9.444ZM1.8,2.911,5.631,6.335,1.8,10.167ZM12.587,6.335l3.831-3.424v7.255ZM3.258,1.8h11.7L9.109,7.03ZM0,13.21H18.218V0H0Z"
          fill={color || "#00d991"}
        />
      </svg>
    )
  }
}

class Logo extends Component {
  render() {
    const { color } = this.props
    return (
      <svg
        viewBox="0 0 254 45"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color || "#00D991"} fillRule="nonzero">
          <rect
            id="Rectangle_75"
            x="27.479"
            y="0.001"
            width="7.591"
            height="11.167"
          ></rect>
          <polygon
            id="Path_101"
            points="0 0 0 44.34 7.591 44.34 7.591 24.74 27.48 24.74 27.48 44.34 35.071 44.34 35.071 18.766 7.591 18.766 7.591 0"
          ></polygon>
          <g id="svg-logo-letters" transform="translate(37.897000, 0.001000)">
            <path
              d="M15.314,11.572 C5.559,11.572 -2.13162821e-14,19.416 -2.13162821e-14,28.286 C-2.13162821e-14,37.156 5.693,45 15.443,45 C23.429,45 28.535,40.756 29.908,34.392 L22.775,34.392 C21.988,36.965 20.157,39.792 15.9,39.792 C10.927,39.792 7.461,36.392 7.132,30.535 L30.499,30.535 C31.481,19.349 25.064,11.572 15.314,11.572 M7.199,25.326 C7.657,20.126 10.536,16.776 15.314,16.776 C20.092,16.776 22.904,20.122 23.362,25.326 L7.199,25.326 Z"
              id="Path_102"
            ></path>
            <path
              d="M46.4,11.572 C37.961,11.572 32.855,16.136 32.593,22.372 L39.726,22.372 C39.926,18.772 42.281,16.772 46.272,16.772 C50.396,16.772 52.885,19.025 52.885,23.331 L52.885,24.295 L44.57,25.13 C37.304,25.903 31.416,28.343 31.416,34.965 C31.416,40.818 36.193,44.995 43.002,44.995 C47.908,44.995 51.574,42.489 52.948,38.049 L53.148,38.049 L53.148,44.349 L60.023,44.349 L60.023,24.62 C60.023,15.941 54.723,11.572 46.407,11.572 M52.891,30.272 C52.891,36.383 49.945,39.787 44.838,39.787 C41.696,39.787 38.95,38.049 38.95,35.032 C38.95,31.943 41.434,30.401 45.75,29.953 L52.887,29.18 L52.891,30.272 Z"
              id="Path_103"
            ></path>
            <path
              d="M87.045,0 L87.045,18.9 L86.845,18.9 C85.3911631,14.4441737 81.1898293,11.4669593 76.504,11.572 C67.798,11.572 62.759,19.287 62.759,28.286 C62.759,37.285 67.607,45 76.508,45 C81.2061081,45.0771314 85.4031562,42.0757611 86.849,37.605 L87.049,37.605 L87.049,44.355 L94.049,44.355 L94.049,0 L87.045,0 Z M78.801,39.792 C73.366,39.792 70.291,35.099 70.291,28.286 C70.291,21.407 73.366,16.776 78.801,16.776 C84.301,16.776 87.307,21.407 87.307,28.286 C87.307,35.099 84.298,39.792 78.801,39.792"
              id="Path_104"
            ></path>
            <rect
              id="Rectangle_76"
              x="98.893"
              y="0"
              width="7.133"
              height="44.356"
            ></rect>
            <path
              d="M123.766,11.572 C115.322,11.572 110.216,16.136 109.954,22.372 L117.087,22.372 C117.287,18.772 119.642,16.772 123.633,16.772 C127.757,16.772 130.246,19.025 130.246,23.331 L130.246,24.295 L121.929,25.132 C114.663,25.905 108.775,28.345 108.775,34.967 C108.775,40.82 113.552,44.997 120.361,44.997 C125.267,44.997 128.934,42.491 130.307,38.051 L130.507,38.051 L130.507,44.351 L137.377,44.351 L137.377,24.62 C137.377,15.941 132.077,11.572 123.765,11.572 M130.244,30.272 C130.244,36.383 127.298,39.787 122.191,39.787 C119.049,39.787 116.303,38.049 116.303,35.032 C116.303,31.943 118.787,30.401 123.103,29.953 L130.24,29.18 L130.244,30.272 Z"
              id="Path_105"
            ></path>
            <path
              d="M158.518,11.572 C153.741,11.572 150.337,14.012 148.963,18.514 L148.826,18.514 L148.826,12.214 L141.889,12.214 L141.889,44.355 L149.026,44.355 L149.026,25.073 C149.026,19.798 151.19,16.843 155.572,16.843 C159.954,16.843 162.118,19.543 162.118,25.522 L162.118,44.355 L169.251,44.355 L169.251,23.464 C169.251,16.394 165.718,11.572 158.519,11.572"
              id="Path_106"
            ></path>
            <path
              d="M196.35,0 L196.35,18.9 L196.15,18.9 C194.696163,14.4441737 190.494829,11.4669593 185.809,11.572 C177.109,11.572 172.068,19.287 172.068,28.286 C172.068,37.285 176.912,45 185.809,45 C190.507108,45.0771314 194.704156,42.0757611 196.15,37.605 L196.35,37.605 L196.35,44.355 L203.35,44.355 L203.35,0 L196.35,0 Z M188.101,39.792 C182.67,39.792 179.591,35.099 179.591,28.286 C179.591,21.407 182.671,16.776 188.101,16.776 C193.601,16.776 196.611,21.407 196.611,28.286 C196.611,35.099 193.598,39.792 188.101,39.792"
              id="Path_107"
            ></path>
            <rect
              id="Rectangle_77"
              x="207.802"
              y="37.156"
              width="7.59"
              height="7.199"
            ></rect>
          </g>
        </g>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.414"
        height="21.414"
        viewBox="0 0 21.414 21.414"
      >
        <g transform="translate(0.207 0.707)">
          <line
            y1="20"
            x2="20"
            transform="translate(0.5)"
            fill="none"
            stroke="#00d991"
            strokeWidth="2"
          />
          <line
            x2="20"
            y2="20"
            transform="translate(0.5)"
            fill="none"
            stroke="#00d991"
            strokeWidth="2"
          />
        </g>
      </svg>
    )
  }
}

const Search = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.736"
    height="17.72"
    viewBox="0 0 17.736 17.72"
  >
    <g fill={color || "#00d991"}>
      <path
        d="M16.04,23.247a7.129,7.129,0,1,1,7.129-7.129,7.137,7.137,0,0,1-7.129,7.129m0-12.48a5.351,5.351,0,1,0,5.351,5.351,5.357,5.357,0,0,0-5.351-5.351"
        transform="translate(-8.911 -8.989)"
      />
      <path
        d="M4.22,2.47H6v7.779H4.22Z"
        transform="translate(6.247 13.457) rotate(-45)"
      />
    </g>
  </svg>
)

const CaseStudy = ({ color = "#f56645" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.894"
    height="23.894"
    viewBox="0 0 23.894 23.894"
  >
    <path
      data-name="Path 680"
      d="M27.078,10H10V27.078h6.816v6.816H33.894V16.816H27.078ZM18.987,24.907V18.99H24.9v5.917Zm-2.174-8.091v8.091H12.171V12.174H24.907v4.641H16.816Z"
      transform="translate(-10 -10)"
      fill={color}
    />
  </svg>
)

const Article = ({ color = "#00d1d9" }) => (
  <svg
    id="Artboard_4_copy_20"
    data-name="Artboard 4 copy 20"
    xmlns="http://www.w3.org/2000/svg"
    width="23.531"
    height="22.634"
    viewBox="0 0 23.531 22.634"
  >
    <rect
      id="Rectangle_404"
      data-name="Rectangle 404"
      width="23.531"
      height="3.482"
      fill={color}
    />
    <rect
      id="Rectangle_405"
      data-name="Rectangle 405"
      width="23.531"
      height="3.482"
      transform="translate(0 19.152)"
      fill={color}
    />
    <rect
      id="Rectangle_406"
      data-name="Rectangle 406"
      width="14.995"
      height="3.482"
      transform="translate(0 12.769)"
      fill={color}
    />
    <rect
      id="Rectangle_407"
      data-name="Rectangle 407"
      width="19.955"
      height="3.482"
      transform="translate(0 6.383)"
      fill={color}
    />
  </svg>
)

const Event = ({ color = "#00d991" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 100 100"
  >
    <path
      fill={color}
      d="M80,42.26v37.74H20V42.26h60m10-10H10v57.74H90V32.26h0Z"
    />
    <rect fill={color} x="10" y="10" width="80" height="11" />
  </svg>
)

const CompanyNews = ({ color = "#00d991" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <circle
      data-name="Artboard 4 copy 17"
      cx="11.5"
      cy="11.5"
      r="11.5"
      fill={color}
    />
  </svg>
)

export const ProductLaunch = ({ color = "#d1de00" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    width="24"
    height="24"
  >
    <path
      d="M2.4,2.4V23.6H23.6V2.4H2.4ZM21.4,21.4H4.6V4.6H21.4V21.4Z"
      fill={color}
    />
    <polygon
      points="11.9 19.53 14.1 19.53 14.1 14.1 19.53 14.1 19.53 11.9 14.1 11.9 14.1 6.47 11.9 6.47 11.9 11.9 6.47 11.9 6.47 14.1 11.9 14.1 11.9 19.53"
      fill={color}
    />
  </svg>
)

const Video = ({ color = "#8f2edb" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.499"
    height="24.5"
    viewBox="0 0 24.499 24.5"
  >
    <path
      d="M22.251,8.34A12.25,12.25,0,1,0,34.5,20.59,12.25,12.25,0,0,0,22.251,8.34Zm0,22.048a9.8,9.8,0,1,1,9.8-9.8A9.808,9.808,0,0,1,22.251,30.388Z"
      transform="translate(-10.002 -8.34)"
      fill={color}
    />
    <path
      d="M40,42.734l8.575-5.742L40,31.25Z"
      transform="translate(-30.813 -24.742)"
      fill={color}
    />
  </svg>
)

const Filter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.975"
    height="15.9"
    viewBox="0 0 18.975 15.9"
  >
    <path
      id="filter_list_FILL1_wght400_GRAD200_opsz24"
      d="M9.65,19.375v-2.65h4.675v2.65ZM5.55,12.75V10.1H18.4v2.65ZM2.5,6.125V3.475H21.475v2.65Z"
      transform="translate(-2.5 -3.475)"
      fill="#434343"
    />
  </svg>
)

const CloseFilter = () => (
  <svg
    className="close-filter"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <line
      id="Line_61"
      data-name="Line 61"
      y2="16"
      transform="translate(8)"
      fill="none"
      stroke="#434343"
      strokeWidth="2"
    />
    <line
      id="Line_62"
      data-name="Line 62"
      y2="16"
      transform="translate(16 8) rotate(90)"
      fill="none"
      stroke="#434343"
      strokeWidth="2"
    />
  </svg>
)

const Arrow = () => (
  <svg viewBox="0 0 23 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="1" fill="none" fillRule="evenodd" stroke="#00D991">
      <line x1="0" y1="6" x2="22" y2="6"></line>
      <line x1="16" y1="0" x2="22" y2="6"></line>
      <line x1="16" y1="12" x2="22" y2="6"></line>
    </g>
  </svg>
)

const Plus = ({ active = false }) => (
  <svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#00D991" strokeWidth="2" fill="none" fillRule="evenodd">
      {!active && <line x1="8" y1="0" x2="8" y2="16" id="Line_61"></line>}
      <line
        x1="8"
        y1="0"
        x2="8"
        y2="16"
        id="Line_62"
        transform="translate(8.000000, 8.000000) rotate(90.000000) translate(-8.000000, -8.000000) "
      ></line>
    </g>
  </svg>
)

const Email = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="alternate_email_FILL1_wght400_GRAD200_opsz24"
      d="M13.333,25.333a11.472,11.472,0,0,1-4.639-.958,12.18,12.18,0,0,1-6.4-6.4,11.748,11.748,0,0,1,0-9.292,12.21,12.21,0,0,1,6.4-6.389,11.748,11.748,0,0,1,9.292,0A12.24,12.24,0,0,1,24.376,8.68a11.445,11.445,0,0,1,.958,4.653v2.028A4.021,4.021,0,0,1,24.042,18.5a4.357,4.357,0,0,1-3.014,1.167,5.13,5.13,0,0,1-3.472-1.389,6.026,6.026,0,0,1-1.986,1.028,7.469,7.469,0,0,1-2.237.361A6.307,6.307,0,0,1,7,13.333,6.309,6.309,0,0,1,13.333,7a6.307,6.307,0,0,1,6.333,6.333v2.028a1.361,1.361,0,0,0,2.722,0V13.333a8.716,8.716,0,0,0-2.667-6.389,8.716,8.716,0,0,0-6.389-2.667A8.716,8.716,0,0,0,6.944,6.944a8.716,8.716,0,0,0-2.667,6.389,8.716,8.716,0,0,0,2.667,6.389,8.716,8.716,0,0,0,6.389,2.667H19v2.944Zm0-8.611a3.367,3.367,0,0,0,3.389-3.389,3.365,3.365,0,0,0-3.389-3.389,3.367,3.367,0,0,0-3.389,3.389,3.369,3.369,0,0,0,3.389,3.389Z"
      transform="translate(-1.333 -1.333)"
      fill="#fff"
    />
  </svg>
)

const Phone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.696"
    height="24"
    viewBox="0 0 15.696 24"
  >
    <path
      id="phone_iphone_FILL1_wght400_GRAD200_opsz24"
      d="M12.152,21.215a1.169,1.169,0,1,0-.822-.342A1.119,1.119,0,0,0,12.152,21.215ZM4.3,24.152v-24H20v24Zm2.684-8.2H17.316V6.278H6.987Z"
      transform="translate(-4.304 -0.152)"
      fill="#fff"
    />
  </svg>
)

const LinkedInLetters = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.69"
    height="20.007"
    viewBox="0 0 20.69 20.007"
  >
    <path
      id="Path_661"
      d="M.513,20.007h0L.342,6.5H4.787l.171,13.332ZM2.565,4.622h0A2.25,2.25,0,0,1,0,2.4,2.383,2.383,0,0,1,2.364,0q.1,0,.2.007A2.466,2.466,0,0,1,5.129,2.229a2.382,2.382,0,0,1-2.363,2.4q-.1,0-.2-.007ZM16.415,19.836V12.657c0-1.88-.684-3.077-2.223-3.077A2.408,2.408,0,0,0,11.97,11.29a2.167,2.167,0,0,0-.171,1.026v7.52H7.353S7.353,7.7,7.182,6.5h4.445v1.88A4.245,4.245,0,0,1,15.56,6.162c2.907,0,5.129,1.88,5.129,5.983v7.692Z"
      transform="translate(0 0)"
      fill="#fff"
    />
  </svg>
)

const Download = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.218"
    height="17.2"
    viewBox="0 0 18.218 17.2"
  >
    <path
      id="Path_642"
      data-name="Path 642"
      d="M16.418,117.4v3.66H1.8V117.4H0v5.46H18.218V117.4Z"
      transform="translate(0 -105.66)"
      fill={color}
    />
    <path
      id="Path_643"
      data-name="Path 643"
      d="M48.751,90.895l-4.7-4.2,1.2-1.342,3.5,3.132,3.5-3.132,1.2,1.342Z"
      transform="translate(-39.642 -76.814)"
      fill={color}
    />
    <rect
      id="Rectangle_293"
      data-name="Rectangle 293"
      width="1.8"
      height="12.874"
      transform="translate(8.209)"
      fill={color}
    />
  </svg>
)

const Machines = ({ color = "#00d991" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.51"
    height="25.51"
    viewBox="0 0 25.51 25.51"
  >
    <path
      d="M10,35.51H35.51V10H10Zm7.94-17.57a6.81,6.81,0,0,1,9.63,9.63h0a6.809,6.809,0,0,1-9.63,0h0a6.809,6.809,0,0,1,0-9.63Z"
      transform="translate(-10 -10)"
      fill={color}
    />
  </svg>
)

const Marker = ({ color = "#00d991" }) => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.26222 0.00100708C4.5878 0.0144794 2.98731 0.69252 1.81278 1.88598C0.63826 3.07945 -0.0140909 4.69059 -0.000776692 6.36501C-0.000776692 9.87901 5.23722 20.001 6.26222 20.001C7.28722 20.001 12.5252 9.87901 12.5252 6.36501C12.5385 4.69059 11.8862 3.07945 10.7117 1.88598C9.53714 0.69252 7.93664 0.0144794 6.26222 0.00100708ZM6.26222 9.13601C5.7121 9.14494 5.17177 8.98996 4.71001 8.69081C4.24825 8.39165 3.88597 7.96185 3.66927 7.45613C3.45258 6.9504 3.39128 6.39164 3.49318 5.85096C3.59509 5.31029 3.85558 4.81217 4.2415 4.42002C4.62742 4.02787 5.12131 3.75944 5.66028 3.6489C6.19925 3.53836 6.75893 3.5907 7.26806 3.79927C7.77719 4.00785 8.21273 4.36321 8.51924 4.82012C8.82574 5.27703 8.98935 5.81481 8.98922 6.36501C8.99482 7.09402 8.71071 7.79541 8.19935 8.31502C7.68799 8.83463 6.99123 9.12993 6.26222 9.13601Z"
      fill="#00D991"
    />
  </svg>
)

const Play = ({ color = "#FFFFFF" }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Artboard 4 copy 18">
      <path
        id="Path 682"
        d="M19.998 0C8.72699 0.23 -0.218009 9.18 -0.00200883 20C-0.222009 30.821 8.72299 39.771 19.998 40C31.269 39.77 40.214 30.82 39.998 20C40.218 9.179 31.273 0.229 19.998 0ZM19.998 36C10.988 35.808 3.83199 28.654 3.99799 20C3.82799 11.344 10.983 4.19 19.998 4C29.008 4.192 36.164 11.346 35.998 20C36.166 28.652 29.011 35.806 19.998 35.998V36Z"
        fill="white"
      />
      <path
        id="Path 683"
        d="M15 29.376L29 20.001L15 10.626V29.376Z"
        fill="white"
      />
    </g>
  </svg>
)

const Stop = ({ color = "#FFFFFF" }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5H19V19H5V5Z" fill={color} fillOpacity="1" />
  </svg>
)

const PrevArrow = ({ onClick, style, className, color="#00D991" }) => {
  let attr = { onClick, style, className }
  return (
    <span {...attr}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.625"
        height="20"
        viewBox="0 0 10.625 20"
      >
        <path
          d="M9.342-31.777.216-22.527a.751.751,0,0,0,0,1.054l9.126,9.25a.749.749,0,0,0,1.06.007.751.751,0,0,0,.007-1.061L1.8-22l8.606-8.723a.751.751,0,0,0-.007-1.061A.746.746,0,0,0,9.876-32,.747.747,0,0,0,9.342-31.777Z"
          transform="translate(0 32)"
          fill={color}
        />
      </svg>
    </span>
  )
}

const NextArrow = ({ onClick, style, className, color="#00D991" }) => {
  let attr = { onClick, style, className }
  return (
    <span {...attr}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.625"
        height="20"
        viewBox="0 0 10.625 20"
      >
        <path
          d="M9.342.223.216,9.473a.751.751,0,0,0,0,1.054l9.126,9.25a.75.75,0,1,0,1.067-1.054L1.8,10l8.606-8.723A.75.75,0,1,0,9.342.223Z"
          transform="translate(10.625 20) rotate(180)"
          fill={color}
        />
      </svg>
    </span>
  )
}

const Image = ({ color = "#FFFFFF" }) => (
  <svg width="40" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
    <path fill={color} d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
  </svg>
)

export {
  Twitter,
  Instagram,
  LinkedIn,
  Facebook,
  LogoMark,
  DownArrow,
  LeftArrow,
  Plus,
  Phone,
  Email,
  LinkedInLetters,
  Mail,
  Logo,
  Close,
  Search,
  CaseStudy,
  Article,
  Event,
  Video,
  Filter,
  CloseFilter,
  Arrow,
  Download,
  Machines,
  CompanyNews,
  Marker,
  Play,
  Stop,
  PrevArrow,
  NextArrow,
  Image
}
