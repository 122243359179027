import { useStaticQuery, graphql } from "gatsby"

const useLatestPost = () => {
  const { wpPost } = useStaticQuery(graphql`
    query LatestPost {
      wpPost
      {
        ...ArticleCard
      }
    }
  `)

  return wpPost
}

export default useLatestPost