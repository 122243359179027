import React from 'react'
import Link from '../../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import TempImage from '../../assets/images/post.jpg'
import { classIcons } from '../../utils/post-types'
import Fade from 'react-reveal/Fade'
import useTypes from '../../hooks/use-types'

const ArticleCard = ({ title, uri, cardImage, categories, types, excerpt, sites }) => {
  const colors = useTypes()

  const show = !!sites?.nodes?.find(site => site.slug === process.env.GATSBY_WP_CATEGORY_SITE_SLUG)
  if (!show) return null

  const type = types?.nodes?.length ? types.nodes[0].name : null
  const color = colors[type] || '#000000'
  const Icon = classIcons[type] || classIcons['Company News']

  return (
    <Link to={uri} className='article'>
      <Fade distance='40px' bottom>
        <div className='article__wrapper' style={{ borderTop: `5px solid ${color}` }}>
        <div className='article__content'>
          <div className='article__type'>
            { Icon && <Icon color={color} /> }
            { type }
          </div>
          <h4
            style={{ textDecorationColor: color }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className='article__excerpt' dangerouslySetInnerHTML={{ __html: excerpt }} />
          <div className='article__categories'>
            <span>Tags:</span>
            <ul>
              {categories?.nodes.map((el, i) => (
                <li key={i}><button>{el.name}</button></li>
              ))}
            </ul>
          </div>
        </div>
        <div className='article__image'>
          {cardImage?.node?.localFile ? <GatsbyImage loading='lazy' image={getImage(cardImage?.node.localFile)} alt={title} /> : <div className='gatsby-image-wrapper'><img src={TempImage} alt={title} /></div>}
        </div>
        </div>
      </Fade>
    </Link>
  )
}

export default ArticleCard