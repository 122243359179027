import { useStaticQuery, graphql } from "gatsby"

const useTypes = () => {
  const { allWpType } = useStaticQuery(graphql`
    query Types {
      allWpType
      {
        nodes {
          name
          slug
          acf {
            color
          }
        }
      }
    }
  `)

  let output = []
  allWpType.nodes?.forEach(el => {
    output[el.name] = el.acf.color
  })
  return output
}

export default useTypes 