import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = props => {

  let newProps = { ...props }
  if (!newProps.to) newProps.to = ''

  const isOutbound = newProps.to && newProps.to.indexOf('//') > -1 && newProps.to.indexOf('//headland') !== -1
  if (isOutbound) {
    // remove domain from url
    let url = newProps.to.split('//')[1].split('/')
    url.shift()
    newProps.to = '/' + url.join('/')
  }
  
  if (newProps.to && newProps.to.indexOf('//') === -1 && newProps.to.indexOf('mailto:') === -1 && newProps.to.indexOf('tel:') === -1) {
    return <GatsbyLink {...newProps}>{newProps.children}</GatsbyLink>
  }

  return (
    <a {...newProps} href={newProps.to} target='_blank' rel='nofollow noopener noreferrer'>
      { newProps.children }
    </a>
  )
}

export default Link
