import React from 'react';

const PopupModal = ({ children = null, active, onClose = () => window._togglePopup() }) => {

  return (
    <div className={`popup-modal ${active ? 'popup-modal--active' : ''}`}>
      <div className='popup-modal__inner'>
        <div className='popup-modal__content'>
          <button 
            type='button' 
            className='popup-modal__close'
            onClick={onClose}
          />
          <div className='popup-modal__content-body'>
            { children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupModal