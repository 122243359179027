import React from 'react'
import Link from '../utils/link'
import Fade from 'react-reveal/Fade'

import { Facebook, Instagram, LinkedIn, Twitter, LogoMark, Arrow, Youtube } from './icons'

const Footer = ({ data }) => {

  const [email, setEmail] = React.useState('')
  const [emailSent, setEmailSent] = React.useState(false)

  const submitNewsletter = (event) => {
    event.preventDefault()
    // TODO: Add newsletter submission
    fetch(`/api/email-signup?email=${email}`, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setEmailSent(true)
      })
  }

  const { acf } = data.wp.siteOptions
  const { footerMenu } = data

  const machines = process.env.GATSBY_WP_CATEGORY_SITE_SLUG === 'new-zealand' ? data.machinesNz.nodes : data.machinesAu.nodes
  const details = process.env.GATSBY_WP_CATEGORY_SITE_SLUG === 'new-zealand' ? acf.newZealand : acf.australia

  footerMenu.nodes.sort((a, b) => a.order - b.order)

  return (
    <footer className='footer'>
      <Fade bottom distance={'40px'}>
        <div className='footer__inner'>

          <div className='footer__contact'>
            <h5>Subscribe to our newsletter to stay in touch with the latest.</h5>
            { emailSent ? (
              <div className='footer__contact-form'><p>{'Thanks for signing up!'}</p></div>
            )
            : (
              <form className='footer__contact-form' onSubmit={submitNewsletter}>
                <input type='email' name='email' placeholder='Enter your email address' onChange={e => setEmail(e.target.value)} />
                <button type='submit' aria-label='Submit'><Arrow /></button>
              </form>
            )}
            { details?.operatingHours && (
              <>
                <h6>Operating Hours</h6>
                <p>{ details.operatingHours }</p>
              </>) 
            }
            { details?.phone && (
              <>
                <h6>Phone</h6>
                <p><a href={`tel:${details.phone}`}>{details.phone}</a></p>
              </>) 
            }
            { details?.addresses?.length > 0 && (
              <>
                <h6>Address</h6>
                { details.addresses?.map(({ address, region }, i) => (
                  <p key={i}>{ region } – { address }</p>
                )) }
              </>)
            }
          </div>

          <div className='footer__menu'>
            {footerMenu?.nodes?.map((el, i) => {
              if (el.parentId !== null) return null
              return (
                <div className='footer__menu-list' key={i}>
                  <Link to={el.uri} title={el.label} className={'footer__menu-title'}>
                    <span>{el.label}</span>
                  </Link>
                  {el.childItems?.nodes?.map(({ uri, label }, i) => {
                    if (el.uri === '/products/' && !machines?.find(machine => machine.uri === uri)) return null
                    return (
                      <Link key={i} to={uri} title={label} className='footer__menu-item'>
                        <span>{label}</span>
                      </Link>
                    )
                  })}
                </div>
              )
            })}
          </div>

        </div>
        <div className='footer__inner'>
          <div className='footer__col'>
            <Link to='/' title='Headland' className='footer__col-logo'>
              <LogoMark color={'#00D991'} />
            </Link>
          </div>

          <div className='footer__col'>
            <h5>Enabling Innovation</h5>

            <ul className='footer__social'>
              {acf.linkedin && <li><Link to={acf.linkedin}><LinkedIn color={'#00D991'} /></Link></li>}
              {acf.youtube && <li><Link to={acf.youtube}><Youtube color={'#00D991'} /></Link></li>}
              {acf.instagram && <li><Link to={acf.instagram}><Instagram color={'#00D991'} /></Link></li>}
              {acf.facebook && <li><Link to={acf.facebook}><Facebook color={'#00D991'} /></Link></li>}
              {acf.twitter && <li><Link to={acf.twitter}><Twitter color={'#00D991'} /></Link></li>}
            </ul>
          </div>
        </div>
      </Fade>
    </footer>
  )
}

export default Footer
